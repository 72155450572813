import GridTwoImage from './GridTwoImage';
import BigHeadingAndDescription from './BigHeadingAndDescription';
import HomepageBanner from './HomepageBanner';
import CategoryTemplate1 from './CategoryTemplate1';
import AwardTemplate1 from './AwardTemplate1';
import CountryTemplate1 from './CountryTemplate1';
import WFHTemplate1 from './WFHTemplate1';
import UtilityTemplate1 from './UtilityTemplate1';
import BrandTemplate1 from './BrandTemplate1';
import Description from './Description';
import GiftingProduct from './GiftingProduct';
import UtilityProduct from './UtilityProduct';
import Heading from './Heading';
// Add other components as needed
const componentRegistry = {
    HomepageBanner,
    CategoryTemplate1,
    AwardTemplate1,
    CountryTemplate1,
    WFHTemplate1,
    UtilityTemplate1,
    BrandTemplate1,
    GridTwoImage,
    BigHeadingAndDescription,
    Heading,
    Description,
    UtilityProduct,
    GiftingProduct
    // Add other components as needed
};

export default componentRegistry;
