import React, { useEffect, useState } from 'react'
const getProduct = async (tag) => {
    if (!tag) return
    const url = `${process.env.REACT_APP_URL}/v1/products/get-all-products?filter[utility_tags][$eq]=${tag}&random=yes&page=1&limit=6&stock_product=true`
    const res = await fetch(url)
    return await res.json()
}
const UtilityProduct = ({ data }) => {
    const { headline, sub_heading, utilityTags } = data;
    const [selectedTag, setSelectedTab] = useState()
    const [products, setProducts] = useState([])
    useEffect(() => {
        if (!utilityTags) return
        setSelectedTab(utilityTags[0])
    }, [utilityTags])

    useEffect(() => {
        if (!selectedTag) return
        getProduct(selectedTag).then(data => setProducts(data.productList))
    }, [selectedTag])
    console.log('selected tag', selectedTag);
    return (
        <div className='bg-[#FBFBFB]'>
            <h3 className='text-xl text-[#E2474B] font-bold '>{sub_heading}</h3>
            <h2 className='text-4xl font-bold'>{headline}</h2>
            <div className='flex flex-row gap-10 mt-10'>
                <div className='w-64 basis-64 flex flex-col space-y-2'>
                    {
                        utilityTags?.map(d => (
                            <div onClick={() => setSelectedTab(d)} className={`curspor pointer py-2 px-4 ${selectedTag == d ? 'bg-[#E2474B] text-white' : 'bg-[#FFFFFF] text-black'}  rounded-lg`}>
                                {d}
                            </div>
                        ))
                    }
                </div>
                <div className='grow flex-1 grid grid-cols-3 gap-5'>
                    {
                        products?.map(d => (
                            <div className='w-full h-full rounded-3xl overflow-hidden'>
                                <a href={`/product/${d._id}`}>
                                    <img src={d.product_images.main_img} alt={d.item_name} />
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default UtilityProduct