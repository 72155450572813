import React, { useState, useEffect } from 'react'
const getProduct = async (tag) => {
    if (!tag) return
    const url = `${process.env.REACT_APP_URL}/v1/products/get-all-products?filter[utility_tags][$eq]=${tag}&random=yes&page=1&limit=6&stock_product=true`
    const res = await fetch(url)
    return await res.json()
}
const GiftingProduct = ({ data }) => {
    const { headline, sub_heading, tag } = data;
    const [products, setProducts] = useState([])
    useEffect(() => {
        if (!tag) return
        getProduct(tag).then(data => setProducts(data.productList))
    }, [tag])
    return (
        <div>
            <h3 className='text-xl text-[#E2474B] font-bold '>{sub_heading}</h3>
            <h2 className='text-4xl font-bold'>{headline}</h2>
            <div className='flex flex-row space-x-5 overflow-x-scroll mt-10'>
                {
                    products?.map(d => (
                        <div className='shrink-0 basis-80 w-80  rounded-3xl overflow-hidden'>
                            <a href={`/product/${d._id}`}>
                                <img src={d.product_images.main_img} alt={d.item_name} />
                            </a>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default GiftingProduct