import React, { useState } from 'react'
import UploadImage from './UploadImage'
import TopBar from './TopBar';
const HomepageBanner = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        heading: initialValues?.heading || ''
    });
    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };
    return (
        <div className='w-full flex flex-col border'>
            <TopBar name='Heading' index={index} />
            <div className='my-2 px-5'>
                <label htmlFor="left-image-with-text-headline" className="text-left block mb-2 font-medium  text-gray-700 ">Heading</label>
                <input
                    type="text"
                    id="left-image-with-text-headline"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}
                    value={formData?.heading}
                    onChange={(e) => handleFieldChange('heading', e.target.value)}
                />
            </div>
        </div>
    )
}

export default HomepageBanner