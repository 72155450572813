'use client'
import React, { useState } from 'react'
import TopBar from './TopBar'
import Editor from './Editor'

const BigHeadingAndDescription = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        large_text: initialValues?.large_text || '',
        body_text: initialValues?.body_text || '',
    });

    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };

    return (
        <div
            id={`standard-image-with light text overlay-${index}}`}
            className='w-full flex flex-col border'>
            <TopBar name="Big Heading And Description" index={index} />

            <div className='mt-5 p-2 relative '>
                <div className="mt-2 p-2">
                    <label htmlFor="image-with-heading-1" className="block mb-2 text-sm  text-gray-700 ">Big Headline</label>
                    <input
                        type="text"
                        id="image-with-heading-1"
                        className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                        placeholder="Enter headline text"
                        maxLength={100}

                        value={formData?.large_text}
                        onChange={(e) => handleFieldChange('large_text', e.target.value)}
                    />
                </div>
                <div className='mt-5 p-2'>
                    <label htmlFor="body-text" className="block mb-2 text-sm  text-gray-700 ">Body text</label>
                    <Editor
                        value={formData?.body_text}
                        maxLength={500}
                        name={`body_text`}
                        onChange={handleFieldChange}
                    />
                </div>
            </div>


        </div>
    )
}

export default BigHeadingAndDescription;