import React, { useState } from 'react';
import SelectInput from './SelectInput';
import ManageContentModal from './ManageContentModal';
const SliderInput = ({ name, sliderIds, onChange, componentIndex, list, componentType }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    // Function to add an input field for a new item
    const addInputField = () => {
        if (sliderIds?.length < 20) {
            onChange(name, [...sliderIds, { id: '' }]);
        } else {
            onChange(name, [{ id: '' }])
        }
    };

    // Function to remove an input field for an item
    const removeInputField = (indexToRemove) => {
        const updatedItems = sliderIds.filter((_, index) => index !== indexToRemove);
        onChange(name, updatedItems);
    };

    // Function to update an item's value
    const updateItemValue = (index, value) => {
        const updatedItems = [...sliderIds];
        updatedItems[index].id = value;
        onChange(name, updatedItems);
    };
    const getLink = (id) => {
        const result = list?.find(d => d._id == id);
        return result ? result.image : null;  // Return the 'image' property if the object is found, otherwise return null
    };

    const updateAll = (updatedItem) => {
        onChange(name, updatedItem)
    }
    console.log('component type', componentType);
    return (
        <>
            <div className='flex gap-x-5  items-center overflow-x-scroll px-2 py-5'>
                {
                    sliderIds?.map((image, index) => (

                        <div className='w-60 relative'>
                            <div className='w-60 h-40 bg-gray-300'>
                                {image.id ? (<img className='w-full h-full' src={getLink(image.id)} />) : null}
                            </div>
                            <SelectInput
                                data={list}
                                sliderIds={sliderIds}
                                index={index}
                                updateItemValue={updateItemValue}
                                selectedId={image.id}
                            />
                            {/* <button type='button' onClick={() => removeInputField(index)} className='absolute -top-4 -right-4 z-10 p-1 rounded-sm border border-gray-300'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-gray-700 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button> */}
                        </div>

                    ))
                }
                <div className='shrink-0 w-60 h-40 flex justify-center items-center'>
                    <button
                        // disabled={sliderIds?.length === 10}
                        type='button'
                        onClick={() => setIsModalOpen(true)}
                        className='text-5xl text-gray-700 font-medium py-1 pb-3 px-4 border border-gray-300  rounded'
                    >
                        +
                    </button>
                </div>
            </div>
            {
                isModalOpen && (
                    <ManageContentModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        componentType={componentType}
                        data={list}
                        updateAll={updateAll}
                        sliderIds={sliderIds}
                    />
                )
            }
        </>
    );
}

export default SliderInput;