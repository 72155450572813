'use client'
import React, { useEffect, useState } from 'react'
import TopBar from './TopBar';
import { Reorder } from 'framer-motion'
const UtilityProduct = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        headline: initialValues?.headline || '',
        sub_heading: initialValues?.sub_heading || '',
        utilityTags: initialValues?.utilityTags || []
    });
    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };
    const [tags, setTags] = useState([])
    const [inputTag, setInputTag] = useState('')
    useEffect(() => {
        if (!tags.length) return
        handleFieldChange('utilityTags', tags)
    }, [tags])
    useEffect(() => {
        if (!initialValues?.utilityTags?.length) return
        setTags(initialValues?.utilityTags)
    }, [initialValues?.utilityTags])
    return (
        <div className='w-full flex flex-col border'>
            <TopBar name="Utility product" index={index} />
            <div className="mt-2 p-2">
                <label htmlFor="slider-image-heading-1" className="block mb-2 text-sm  text-gray-700 ">Headline</label>
                <input
                    type="text"
                    id="slider-image-heading-1"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}

                    value={formData?.headline}
                    onChange={(e) => handleFieldChange('headline', e.target.value)}
                />
            </div>
            <div className="mt-2 p-2">
                <label htmlFor="slider-image-sub-heading" className="block mb-2 text-sm  text-gray-700 ">Sub Heading</label>
                <input
                    type="text"
                    id="slider-image-sub-heading"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}

                    value={formData?.sub_heading}
                    onChange={(e) => handleFieldChange('sub_heading', e.target.value)}
                />
            </div>
            <div className='grid grid-cols-2 gap-2'>

                <div className='flex items-start '>
                    <div className="mt-2 p-2  flex space-x-2 items-end">
                        <div>
                            <label htmlFor="slider-image-sub-heading" className="block mb-2 text-sm  text-gray-700 ">Tag</label>
                            <input
                                type="text"
                                id="slider-image-sub-heading"
                                className="block w-80 text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                                placeholder="Enter tag"
                                maxLength={100}
                                value={inputTag}

                                onChange={(e) => setInputTag(e.target.value)}
                            />
                        </div>
                        <button onClick={() => {
                            setTags(prev => {
                                if (!inputTag || prev.includes(inputTag)) {
                                    return prev; // Return the current state if inputTag is invalid or already exists
                                }
                                return [...prev, inputTag]; // Add the new tag to the state
                            });

                            setInputTag('')
                        }}
                            type='button' className='py-1 px-4 rounded-lg bg-black text-white mt-2'>Add Tag</button>
                    </div>

                </div>
                <div className='p-5 pr-20'>
                    <Reorder.Group values={tags} onReorder={setTags}>
                        {
                            tags?.map((item, index) => (
                                <Reorder.Item value={item} key={item}>
                                    <div className='flex justify-between items-center shadow p-1 relative mb-1'>
                                        <div>
                                            <span className='mr-2'>{index + 1}</span>  <span> {item}</span>
                                        </div>
                                        <span className='cursor-grabbing '>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                                        </span>
                                        <button onClick={() => {
                                            setTags(prev => prev.filter(tag => tag !== item));
                                        }}
                                            className='absolute top-2 -right-5' type='button'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-red-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                </Reorder.Item>
                            ))
                        }
                    </Reorder.Group>
                </div>
            </div>
        </div>
    )
}

export default UtilityProduct