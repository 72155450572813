import React, { useState } from "react";
import CityListModal from "./CityListModal";
import Zoom from "react-medium-image-zoom";

const CityListRow = ({
  srNo,
  parent,
  sub,
  categoriesId,
  banner,
  app_banner,
  desc,
  id,
  onActionComplete,
}) => {
  // State for view modal
  const [viewModal, setViewModal] = useState(false);
  // State for edit
  const [editModal, setEditModal] = useState(false);

  const handleClose = () => {
    setViewModal(false);
    setEditModal(false);
    onActionComplete(); // Refresh data after closing modal
  };

  const handleDelete = () => {
    const token = localStorage.getItem("access_token");
    fetch(`${process.env.REACT_APP_URL}/v1/city/delete-city/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        onActionComplete(); // Refresh data after deletion
      })
      .catch((error) => console.log(error));
  };

  return (
    <tr className="overflow-hidden border-b-2 rounded-b-3xl bg-white transition duration-300 ease-in-out">
      <td className="whitespace-nowrap px-6 py-2 items-left flex text-xs font-light text-gray-900">
        {parent}
      </td>
      <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
        <Zoom>
          <img className="w-6 h-6 rounded-full" src={sub} alt="" />
        </Zoom>
      </td>
      <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
        <Zoom>
          <img className="w-10 h-10" src={banner} alt="" />
        </Zoom>
      </td>

      <td className="whitespace-nowrap px-6 py-2 text-xs font-light text-gray-900">
        <div className="flex gap-2">
          <div onClick={() => setViewModal(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div onClick={() => setEditModal(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
          </div>
          <div onClick={handleDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-4 w-4  text-red-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          </div>
          {viewModal && (
            <CityListModal
              id={id}
              modalName="view"
              visible={viewModal}
              onClose={handleClose}
            />
          )}
          {editModal && (
            <CityListModal
              id={id}
              visible={editModal}
              modalName="edit"
              onClose={handleClose}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default CityListRow;
