
import React, { useState, useEffect } from 'react'
import toast from 'react-hot-toast'
import { getToken } from '../../../hook/getToken'
// import { useRouter } from 'next/navigation'
// import { useCms } from '@/context/CmsProvider'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import TableRowAdmin from './TableRowAdmin'
const ContentListAdmin = () => {
    const [searchParams] = useSearchParams()
    const { id } = useParams()
    const navigate = useNavigate()
    const contentType = searchParams.get('content_type')
    const [data, setData] = useState({})
    useEffect(() => {
        if (!contentType) return
        const apiUrl = `${process.env.REACT_APP_URL}/v1/cms/homepage-content?status=active&content_type=${contentType}`;

        // Replace 'YOUR_BEAproductId, variationIdRER_TOKEN' with your actual bearer token
        fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log('list data', data);
                setData(data.response)
            })
            .catch(error => console.error('Error:', error));

    }, [contentType])

    const handleCreate = (sessionId) => {
        const redirectUrl = `/home-cms/content/add-edit`
        navigate(redirectUrl)
    }
    console.log('content data', data);
    return (
        <main className='flex flex-col m-5'>
            <div className='flex flex-row justify-between items-center'>
                <div >
                    <h1 className='text-xl text-gray-900 font-medium py-2'>Home Page Template List</h1>
                </div>

            </div>
            <section className='flex flex-col border border-solid border-[#EEE] mt-10'>
                <div className='flex flex-col p-5 bg-gray-50'>
                    <h6 className='text-start'>Content List</h6>
                </div>

                {/* Table Section */}
                <div className='relative overflow-hidden'>
                    <table className="w-full text-left text-xs">
                        <thead className="bg-gray-50 text-xs font-medium  text-[#666666]">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Content name
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Content desc
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Last modified
                                </th>
                                <th scope="col" className="px-6 py-3">Approval status</th>
                                <th scope="col" className="px-6 py-3">Edit/Preview</th>
                                <th scope="col" className="px-6 py-3">Approve</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length > 0 ? (
                                    data.map((d, index) => (
                                        <TableRowAdmin key={index} data={d} sessionId={id} />
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No Content</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                </div>
                {/* <div className='flex justify-end items-center py-5 mx-5'>
                    <Pagination
                        currentPage={currentPage}
                        totalItems={totalItems}
                        pageSize={pageSize}
                        setCurrentPage={setCurrentPage}
                    />
                </div> */}
            </section>

        </main>

    )
}

export default ContentListAdmin