import React, { useEffect, useState } from "react";

const CityListModal = ({ visible, onClose, id, modalName }) => {
  const [fileInputState, setFileInputState] = useState("");
  const [logo, setLogo] = useState();
  const [banner, setBanner] = useState();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (e.target.name === "logoInput") {
        setLogo(reader.result); // Update logo state
      } else if (e.target.name === "bannerInput") {
        setBanner(reader.result); // Update banner state
      }
    };
  };

  const updateData = (url, requestBody) => {
    fetch(url, requestBody)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          onClose();
          console.log(data);
        }
      })
      .catch((error) => console.log(error));
  };

  const [formData, setFormData] = useState({
    country_name: "",
    image: "",
    banner: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({ ...preValue, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("access_token");
    const data = {
      name: formData.country_name, // Change to 'name' for city
      image: logo,
      banner: banner,
    };
    
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    };

    const url =
      modalName === "edit"
        ? `${process.env.REACT_APP_URL}/v1/city/update-city/${id}`
        : `${process.env.REACT_APP_URL}/v1/city/add-city`; // Change to city add endpoint

    updateData(url, requestOptions);

    setFormData({
      country_name: "",
      image: "",
      banner: "",
    });
    setLogo(null);
    setBanner(null);
  };

  const [view, setView] = useState([]);
  useEffect(() => {
    if (modalName === "view" || modalName === "edit") {
      const token = localStorage.getItem("access_token");
      fetch(`${process.env.REACT_APP_URL}/v1/city/get-city/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setView(data.data);
          setFormData({
            country_name: data.data.name,
            image: data.data.image,
            banner: data.data.banner,
          });
          setLogo(data.data.image);
          setBanner(data.data.banner);
        });
    }
  }, [id, modalName]);

  if (!visible) return null;

  return (
    <div className="fixed z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
      <div className="mx-auto bg-white rounded-lg py-5 px-10 shadow-lg">
        <form onSubmit={handleSubmit} className="flex flex-col space-y-5">
          <div className="flex gap-5">
            <div className="w-1/2">
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">
                City Name
              </p>
              <textarea
                onChange={handleChange}
                name="country_name"
                value={formData.country_name}
                placeholder={view && view.name}
                disabled={modalName === "view"}
                className="h-10 w-full my-5 px-4 py-2 outline-0 border border-solid border-gray-200 resize-none rounded-md"
                type="text"
              />
            </div>

            {/* Logo Upload and Preview */}
            <div className="w-1/2">
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">Logo</p>
              <div>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  name="logoInput"
                  className="border mt-6 p-2 w-full rounded-md"
                />
              </div>
              {logo && (
                <div className="flex gap-x-2 items-center mt-4">
                  <p>Preview:</p>
                  <img
                    src={logo}
                    alt="Logo Preview"
                    className="w-24 h-24 object-cover rounded-md"
                  />
                </div>
              )}
            </div>

            {/* Banner Upload and Preview */}
            <div className="w-1/2">
              <p className="w-fit text-sm text-gray-900 py-1 uppercase">
                Banner
              </p>
              <div>
                <input
                  onChange={handleFileInputChange}
                  disabled={modalName === "view"}
                  type="file"
                  name="bannerInput"
                  className="border mt-6 p-2 w-full rounded-md"
                />
              </div>
              {banner && (
                <img
                  src={banner}
                  alt="Banner Preview"
                  className="mt-4 w-48 h-24 object-cover rounded-md"
                />
              )}
            </div>
          </div>

          <div className="flex gap-x-5 justify-center my-5">
            <div
              onClick={onClose}
              className="py-2 px-4 bg-white text-red-600 rounded-md cursor-pointer border border-red-600"
            >
              Cancel
            </div>
            {modalName !== "view" && (
              <button
                className="py-2 px-10 bg-[#00388c] text-white rounded-md uppercase"
                type="submit"
              >
                {modalName === "edit" ? "Update" : "Add New"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CityListModal;
