'use client'
import React, { useState } from 'react'
import ImagesSliderInput from './ImagesSliderInput'
import TopBar from './TopBar';
const GiftingProduct = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        headline: initialValues?.headline || '',
        sub_heading: initialValues?.sub_heading || '',
        tag: initialValues?.tag || ''
    });
    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };
    return (
        <div className='w-full flex flex-col border'>
            <TopBar name="Gifting product" index={index} />
            <div className="mt-2 p-2">
                <label htmlFor="slider-image-heading-1" className="block mb-2 text-sm  text-gray-700 ">Headline</label>
                <input
                    type="text"
                    id="slider-image-heading-1"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}

                    value={formData?.headline}
                    onChange={(e) => handleFieldChange('headline', e.target.value)}
                />
            </div>
            <div className="mt-2 p-2">
                <label htmlFor="slider-image-sub-heading" className="block mb-2 text-sm  text-gray-700 ">Sub Heading</label>
                <input
                    type="text"
                    id="slider-image-sub-heading"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}

                    value={formData?.sub_heading}
                    onChange={(e) => handleFieldChange('sub_heading', e.target.value)}
                />
            </div>
            <div className="mt-2 p-2">
                <label htmlFor="tag" className="block mb-2 text-sm  text-gray-700 ">Tag</label>
                <input
                    type="text"
                    id="tag"
                    className="block w-full text-gray-900 border border-gray-300 rounded-sm bg-gray-50 outline-none py-1 px-2 text-sm"
                    placeholder="Enter headline text"
                    maxLength={100}

                    value={formData?.tag}
                    onChange={(e) => handleFieldChange('tag', e.target.value)}
                />
            </div>

        </div>
    )
}

export default GiftingProduct