'use client'
import React, { Suspense, useState } from 'react'
import TopBar from './TopBar';
const Editor = React.lazy(() => import('./Editor'));

const ProductDescription = ({ index, initialValues, onDataChange }) => {
    const [formData, setFormData] = useState({
        body_text: initialValues?.body_text || '',
    });

    const handleFieldChange = (field, value) => {
        const updatedFormData = { ...formData, [field]: value };
        setFormData(updatedFormData);
        onDataChange(updatedFormData);
    };

    return (
        <div
            id={`standard_product_description_${index}}`}
            className='w-full flex flex-col border'>
            <TopBar name='Description' index={index} />


            <div className='mt-5 p-2'>
                <label htmlFor="body-text" className="block mb-2 text-sm  text-gray-700 ">Body text</label>
                <Suspense >
                    <Editor
                        value={formData?.body_text}
                        isRequired={true}
                        maxLength={500}
                        name={`body_text`}
                        onChange={handleFieldChange}
                    />
                </Suspense>

            </div>

        </div>
    )
}

export default ProductDescription;